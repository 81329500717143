import React, {useState, useRef, useEffect, SetStateAction} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { type } from '@testing-library/user-event/dist/type';
import {useNavigate} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import setupIndexedDB, { useIndexedDBStore } from "use-indexeddb";
import axios, {AxiosError} from 'axios';
import Grid from '@mui/material/Unstable_Grid2';
import Slider from '@mui/material/Slider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { KeyObject } from 'crypto';
import { Volcano } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
  ChartData,
  ChartDatasetProperties,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title
} from 'chart.js';
import {Doughnut, Bar} from 'react-chartjs-2';

ChartJS.register(
  ArcElement,
  ChartTooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
);

// Database Configuration
const idbConfig = {
  databaseName: "energy-db",
  version: 1,
  stores: [
    {
      name: "data",
      id: { keyPath: "sessionId", autoIncrement: false },
      indices: [
        { name: "data", keyPath: "data" },
        { name: "serviceId", keyPath: "serviceId" },
      ],
    },
  ],
};

const GLOBAL_SESSION_ID = '7759f823-05e3-4ed9-be66-7db4ba5718ac';

const baseUrl = window.location.hostname === 'localhost' ?
  window.location.protocol + '//' + window.location.hostname + ':8080/api' :
  window.location.protocol + '//wattadvisor-server.m12p.net/api';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </Box>;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type propertyType = 'power' | 'capacity' | 'area';
type resolutionStep = '1Y' | '1M' | '1W' | '1D' | '1H' | '15T' | '1T';
type timeSeriesConsumptionType = 'electricityConsumption' | 'heatConsumption' | 'gasConsumption';
type timeSeriesPurchaseType = 'electricalEnergyPurchase' | 'electricalEnergyFeedin' | 'thermalEnergyPurchase' | 'naturalGasPurchase';
type timeSeriesType = timeSeriesConsumptionType | timeSeriesPurchaseType;
type assetType = 'PHOTOVOLTAIK_ROOF' | 'PHOTOVOLTAIK_FREE_FIELD' | 'WIND_POWER' | 'COMBINED_HEAT_POWER' | 
  'SOLARTHERMAL_ENERGY' | 
  'ELECTRICAL_ENERGY_STORAGE' | 'THERMAL_ENERGY_STORAGE' |
  'HEAT_PUMP_AIR' | 'HEAT_PUMP_GROUND' | 'GAS_BOILER';
type capexOpexPropertyType = 'capex' | 'opex';
type financialPropertyType = capexOpexPropertyType | 'lifespan';

type STATUS = 'NEW' | 'UPDATED' | 'PROCESSING' | 'SUCCESS' | 'ERROR';

const assetConfigs: Record<assetType, Record<string, string | boolean>> = {
  PHOTOVOLTAIK_ROOF: {title: 'PV Dach', power: true, capacity: false, area: false, group: 'primaryGeneration'}, 
  PHOTOVOLTAIK_FREE_FIELD: {title: 'PV Freifläche', power: true, capacity: false, area: false, group: 'primaryGeneration'}, 
  WIND_POWER: {title: 'Windrad', power: true, capacity: false, area: false, group: 'primaryGeneration'}, 
  COMBINED_HEAT_POWER: {title: 'Kraft-Wärme-Kopplung', power: true, capacity: false, area: false, group: 'primaryGeneration'},
  SOLARTHERMAL_ENERGY: {title: 'Solarthermie', power: false, capacity: false, area: true, group: 'primaryGeneration'},
  ELECTRICAL_ENERGY_STORAGE: {title: 'Elektrischer Speicher', power: true, capacity: true, area: false, group: 'storage'},
  THERMAL_ENERGY_STORAGE: {title: 'Thermischer Speicher', power: true, capacity: true, area: false, group: 'storage'},
  HEAT_PUMP_AIR: {title: 'Luftwärmepumpe', power: true, capacity: false, area: false, group: 'converter'},
  HEAT_PUMP_GROUND: {title: 'Erdwärmepumpe', power: true, capacity: false, area: false, group: 'converter'},
  GAS_BOILER: {title: 'Gas Boiler', power: true, capacity: false, area: false, group: 'converter'},
};

type FinancialValueType = {assetType: assetType, config: Record<propertyType, Record<capexOpexPropertyType, number> | null> & {'lifespan': number}, default: boolean}
type FinancialValuesType = Record<string, FinancialValueType>;

const DEFAULT_VALUES : {
    interestRate: number, 
    lifespan: number, 
    timeSeriesResolution: Record<timeSeriesType, resolutionStep>,
    timeSeriesValues: Record<timeSeriesType, number[]>,
    energyTariffsPriceForPower: Record<timeSeriesPurchaseType, number>,
    financialValues: FinancialValuesType
  } = {
  interestRate: 0.05,
  lifespan: 20,
  timeSeriesResolution: {
    'electricityConsumption': '1Y',
    'heatConsumption': '1Y',
    'gasConsumption': '1Y',
    'electricalEnergyPurchase': '1Y',
    'electricalEnergyFeedin': '1Y',
    'thermalEnergyPurchase': '1Y',
    'naturalGasPurchase': '1Y',
  } as Record<timeSeriesType, resolutionStep>,
  timeSeriesValues: {
    'electricityConsumption': [],
    'heatConsumption': [],
    'gasConsumption': [],
    'electricalEnergyPurchase': [0.3],
    'electricalEnergyFeedin': [0.06],
    'thermalEnergyPurchase': [],
    'naturalGasPurchase': [],
  },
  energyTariffsPriceForPower: {
    'electricalEnergyPurchase': 0,
    'electricalEnergyFeedin': 0,
    'thermalEnergyPurchase': 0,
    'naturalGasPurchase': 0,
  },
  financialValues: {
    'PV Dach (Standard)': {assetType: 'PHOTOVOLTAIK_ROOF', config: { power : {capex: 1200, opex: 50}, capacity : null, area : null, lifespan: 30}, default: true}, 
    'PV Freifläche (Standard)': {assetType: 'PHOTOVOLTAIK_FREE_FIELD', config: { power : {capex: 1200, opex: 50}, capacity : null, area : null, lifespan: 30}, default: true}, 
    'Windrad (Standard)': {assetType: 'WIND_POWER', config: { power : {capex: 1000, opex: 1}, capacity : null, area : null, lifespan: 20}, default: true}, 
    'Kraft-Wärme-Kopplung (Standard)': {assetType: 'COMBINED_HEAT_POWER', config: { power : {capex: 1000, opex: 1}, capacity : null, area : null, lifespan: 20}, default: true}, 
    'Solarthermie (Standard)': {assetType: 'SOLARTHERMAL_ENERGY', config: { power : null, capacity : null, area : { capex: 1000, opex: 1 }, lifespan: 20}, default: true}, 
    'Elektrischer Speicher (Standard)': {assetType: 'ELECTRICAL_ENERGY_STORAGE', config: { power : { capex: 1000, opex: 50}, capacity : { capex: 1000, opex: 50}, area : null, lifespan: 20}, default: true}, 
    'Thermischer Speicher (Standard)': {assetType: 'THERMAL_ENERGY_STORAGE', config: { power : { capex: 1000, opex: 1}, capacity : { capex: 1000, opex: 50}, area : null, lifespan: 20}, default: true}, 
    'Luftwärmepumpe (Standard)': {assetType: 'HEAT_PUMP_AIR', config: { power : {capex: 1000, opex: 1}, capacity : null, area : null, lifespan: 20}, default: true}, 
    'Erdwärmepumpe (Standard)': {assetType: 'HEAT_PUMP_GROUND', config: { power : {capex: 1000, opex: 1}, capacity : null, area : null, lifespan: 20}, default: true}, 
    'Gas Boiler (Standard)': {assetType: 'GAS_BOILER', config: { power : {capex: 1000, opex: 1}, capacity : null, area : null, lifespan: 20}, default: true}, 
  }
};

function App() {

  const search = window.location.search;
  const [sessionId, setSessionId] = 
    useState<string | null>(new URLSearchParams(search).get('sessionId'));

  const [requestDataValid, setRequestDataValid] = 
    useState<boolean>(false);

  const [serviceId, setServiceId] = 
    useState<null | string>(null);
  const [serviceIdOnlyPurchase, setServiceIdOnlyPurchase] = 
    useState<null | string>(null);
  const [serviceIdNoRestrictions, setServiceIdNoRestrictions] = 
    useState<null | string>(null);

  const [computeState, setComputeState] = 
    useState<null | STATUS>(null);
  const [computeStateOnlyPurchase, setComputeStateOnlyPurchase] = 
    useState<null | STATUS>(null);
  const [computeStateNoRestrictions, setComputeStateNoRestrictions] = 
    useState<null | STATUS>(null);

  const isFetching = 
    useRef<boolean>(false);
  const isFetchingOnlyPurchase = 
    useRef<boolean>(false);
  const isFetchingNoRestrictions = 
    useRef<boolean>(false);

  const [computeResult, setComputeResult] = 
    useState<null | any>(null);
  const [computeResultInitial, setComputeResultInitial] = 
    useState<null | any>(null);
  const [computeResultOnlyPurchase, setComputeResultOnlyPurchase] = 
    useState<null | any>(null);
  const [computeResultNoRestrictions, setComputeResultNoRestrictions] = 
    useState<null | any>(null);

  const fetchInterval = useRef<null | ReturnType<typeof setInterval>>(null);
  const fetchIntervalOnlyPurchase = useRef<null | ReturnType<typeof setInterval>>(null);
  const fetchIntervalNoRestrictions = useRef<null | ReturnType<typeof setInterval>>(null);

  const initializingData = useRef<null | boolean>(null);

  const navigate = useNavigate();

  const [valueMain, setValueMain] = useState(0);
  const handleChangeMain = (event: React.SyntheticEvent, newValue: number) => {
    setValueMain(newValue);
  };
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [value2, setValue2] = useState(0);

  const [addConsumption, setAddConsumption] = useState<timeSeriesConsumptionType | null>(null);
  const [addPurchase, setAddPurchase] = useState<timeSeriesPurchaseType | null>(null);
  const [addAssetType, setAddAssetType] = useState<assetType | null>(null);
  const [addAssetName, setAddAssetName] = useState<string | null>(null);

  // location
  const [location, setLocation] = useState<string>('');
  const [longitude, setLongitude] = useState<number | null>(8.3874048);
  const [latitude, setLatitude] = useState<number | null>(49.0148517);

  const resolutions : Record<resolutionStep, number> = {'1Y' : 1, '1M': 12, '1W': 52, '1D': 365, '1H': 24*365, '15T': 24*365*4, '1T' : 24*365*60};
  const resolutionSteps = Object.keys(resolutions) as resolutionStep[];

  // consumption
  const [timeSeriesResolution, setTimeSeriesResolution] = useState<Record<timeSeriesType, resolutionStep>>(DEFAULT_VALUES.timeSeriesResolution);
  const [timeSeriesValues, setTimeSeriesValues] = useState<Record<timeSeriesType, number[]>>(DEFAULT_VALUES.timeSeriesValues);
  const [timeSeriesErrors, setTimeSeriesErrors] = useState<Partial<Record<timeSeriesType, null | string>>>({});

  // asset
  const assetTypes = Object.keys(assetConfigs) as assetType[];
  const [assetValues, setAssetValues] = useState<Record<string, {assetType: assetType, assetFinancial: string, config: Record<propertyType, number>}>>({});
  const [assetErrors, setAssetErrors] = useState<Record<string, Partial<Record<propertyType, string>>>>({});

  // asset excludes
  type AssetExcludesType = Record<string, {select: 'UNLIMITED' | 'NO_BUILD' | 'DECONSTRUCT' | 'CUSTOM', 
      customExcludes : Partial<Record<propertyType, null | number>>}>;
  const [assetExcludes, setAssetExcludes] = 
    useState<AssetExcludesType>(
      deltaAssetExcludes(DEFAULT_VALUES.financialValues, {}));
  const [assetExcludesErrors, setAssetExcludesErrors] = 
    useState<Record<string, Partial<Record<propertyType, string>>>>({});

  // energy tariff
  const [energyTariffsPriceForPower, setEnergyTariffsPriceForPower] = 
    useState<Record<timeSeriesPurchaseType, number>>(DEFAULT_VALUES.energyTariffsPriceForPower);
  const [energyTariffsPriceForPowerErrors, setEnergyTariffsPriceForPowerErrors] = 
    useState<Partial<Record<timeSeriesPurchaseType, string>>>({});

  // financial values
  const [financialValues, setFinancialValues] = 
    useState<FinancialValuesType>(DEFAULT_VALUES.financialValues);
  const [financialValuesErrors, setFinancialValuesErrors] = 
    useState<Record<string, Partial<Record<propertyType, Partial<Record<capexOpexPropertyType, string>>>> & {'lifespan'?: string}>>({});

  const [addAssetFinancialDataAssetType, setAddAssetFinancialDataAssetType] = useState<assetType | null>(null);
  const [addAssetFinancialDataName, setAddAssetFinancialDataName] = useState<string | null>(null);

  const [interestRate, setInterestRate] = 
    useState<number>(DEFAULT_VALUES.interestRate);

  const [result, setResult] = useState<Result>({});
  const lastData = useRef<null | number[]>(null);
  const propMoved = useRef<null | 'is' | 'target' | 'max'>(null);

  useEffect(() => {
    setupIndexedDB(idbConfig)
      .then(async () => {
        if (sessionId && initializingData.current === null) {
          initializingData.current = true;
          await refreshState(sessionId);
          initializingData.current = false;
        }
      })
      .catch(e => console.error("error / unsupported", e));
  }, []);

  const { add, getByID, update, deleteAll } = useIndexedDBStore("data");

  useEffect(() => {
    if (sessionId === null) {
      newSession();
    }
  }, []);

  useEffect(() => {
    if (sessionId && initializingData.current === false) {
      refreshState(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    setAddAssetFinancialDataName('Anlage ' + Object.keys(financialValues).length);
  }, [financialValues]);

  useEffect(() => {
    setAddAssetName('Anlage ' + Object.keys(assetValues).length);
  }, [assetValues]);

  useEffect(() => {
    const delta = deltaAssetExcludes(financialValues, assetExcludes);
    if (Object.keys(delta).length > 0) {
      setAssetExcludes(ae => {
        return {...ae, ...delta}; 
      })
    }
  }, [financialValues, assetExcludes]);

  useEffect(() => {
    if (computeResult !== null && valueMain !== 1)
      setValueMain(1);
  }, [computeResult]);

  function clearDB() {
    deleteAll()
      .then(_ => alert('Browser-DB erfolgreich geleert'))
      .catch(m => alert(JSON.stringify(m)));
  };

  function deltaAssetExcludes(
      financialValues: FinancialValuesType, assetExcludes: AssetExcludesType) {
    const delta: AssetExcludesType = {}; 
    Object.keys(financialValues).forEach(assetTitle => {
      if (!(assetTitle in assetExcludes)) {
        delta[assetTitle] = {select: 'UNLIMITED', customExcludes: {power: null, capacity: null, area: null}};
      }
    })
    return delta;
  };

  const [alignmentChart, setAlignmentChart] = useState<'distribution' | 'fragments' | 'compare'>('compare');
  const handleChangeChart = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: 'distribution' | 'fragments' | 'compare',
  ) => {
    setAlignmentChart(newAlignment);
  };

  function hashStringToColor(str: string, a: number) {
    let hash = 5381;
    for(let i = 0; i < str.length; i++) {
      hash = ((hash << 5) + hash) + str.charCodeAt(i); /* hash * 33 + c */
    }
    const r = (hash & 0xFF0000) >> 16;
    const g = (hash & 0x00FF00) >> 8;
    const b = hash & 0x0000FF;
    return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
}

  const [energyComponentDistributionData, setEnergyComponentDistributionData] = useState<ChartData<'doughnut', number[], string>>();
  const [costFragmentsData, setCostFragmentsData] = useState<ChartData<'bar', number[], string>>();
  const [costCompareData, setCostCompareData] = useState<ChartData<'bar', number[], string>>();

  // distribution
  useEffect(() => {
    if(!computeResult || computeResult.target_scenario.components.length === 0) {
      setEnergyComponentDistributionData(undefined);
      return;
    }

    const labels: string[] = [];
    const datasets: any[] = [{
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    }];
    const financialValuesKeys = Object.keys(financialValues);

    computeResult.target_scenario.components.forEach((component: any, idx: number) => {
      const producedEnergy = (component.produced_energy ?? []).map((pe: {amount:number}) => pe.amount)
        .reduce((accumulator:number, currentValue:number) => accumulator + currentValue, 0);
      if (producedEnergy === 0) return;

      const label = (() => {
        if (financialValuesKeys.length > idx) {
          return financialValuesKeys[idx];
        } else {
          return tariffReadable(component.component_type) ?? 'Unbekannt';
        }
      })();
      labels.push(label);

      datasets[0].data.push(producedEnergy);
      datasets[0].backgroundColor.push(hashStringToColor(label, 0.5));
      datasets[0].borderColor.push(hashStringToColor(label, 1));
    });

    setEnergyComponentDistributionData({
      labels,
      datasets,
    });
  }, [computeResult]);

  // fragments
  useEffect(() => {
    if(!computeResult || computeResult.target_scenario.components.length === 0) {
      setCostFragmentsData(undefined);
      return;
    }

    const labels: string[] = [];
    const datasets: any[] = [{
      label: 'Jährliche Investitionskosten in EUR',
      data: [], // investment
      backgroundColor: hashStringToColor('me', 0.1),
    }, { // operational
      label: 'Jährliche Betriebskosten in EUR',
      data: [],
      backgroundColor: hashStringToColor('me', 0.5),
    }, { // purchase
      label: 'Jährlicher Einkauf in EUR',
      data: [],
      backgroundColor: hashStringToColor('me', 0.9),
    }];
    const financialValuesKeys = Object.keys(financialValues);

    computeResult.target_scenario.components.forEach((component: any, idx: number) => {
      const producedEnergy = (component.produced_energy ?? []).map((pe: {amount:number}) => pe.amount)
        .reduce((accumulator:number, currentValue:number) => accumulator + currentValue, 0);
      if (producedEnergy === 0) return;

      const label = (() => {
        if (financialValuesKeys.length > idx) {
          return financialValuesKeys[idx];
        } else {
          return tariffReadable(component.component_type) ?? 'Unbekannt';
        }
      })();
      labels.push(label);

      if ('investment_cost' in component) {
        datasets[0].data.push(component.annuity - (component.operational_cost ?? 0));
      } else {
        datasets[0].data.push(0);
      }

      if ('operational_cost' in component) {
        datasets[1].data.push(component.operational_cost);
      } else {
        datasets[1].data.push(0);
      }

      if ('purchase_cost' in component) {
        datasets[2].data.push(component.purchase_cost);
      } else {
        datasets[2].data.push(0);
      }
    });

    setCostFragmentsData({
      labels,
      datasets,
    });
  }, [computeResult]);

  // compare
  useEffect(() => {
    const labels: string[] = [];
    const datasets: any[] = [{
      label: 'Jährliche Investitionskosten in EUR',
      data: [], // investment
      backgroundColor: hashStringToColor('me', 0.1),
    }, { // operational
      label: 'Jährliche Betriebskosten in EUR',
      data: [],
      backgroundColor: hashStringToColor('me', 0.5),
    }, { // purchase
      label: 'Jährlicher Einkauf in EUR',
      data: [],
      backgroundColor: hashStringToColor('me', 0.9),
    }, { // income
      label: 'Jährliches Einkommen in EUR',
      data: [],
      backgroundColor: hashStringToColor('em', 0.9),
    }];

    const processKPIs = function(computeResult2: any, label: string) {
      if(computeResult2 && computeResult2.target_scenario.kpis) {
        labels.push(label);

        const kpis = computeResult2.target_scenario.kpis;

        datasets[0].data.push(kpis.total_annuities - kpis.total_operational_cost - kpis.total_purchase_cost);
        datasets[1].data.push(kpis.total_operational_cost);
        datasets[2].data.push(kpis.total_purchase_cost);
        datasets[3].data.push(kpis.total_income);
      }
    }

    processKPIs(computeResultOnlyPurchase, 'Nur Bezug');
    processKPIs(computeResultNoRestrictions, 'Initiale Konfiguration ohne Beschränkungen');
    processKPIs(computeResultInitial, 'Initiale Konfiguration');
    processKPIs(computeResult, 'Angepasste Konfiguration');

    setCostCompareData({
      labels,
      datasets,
    });
  }, [computeResult, computeResultInitial, computeResultOnlyPurchase, computeResultNoRestrictions]);

  interface Response {
    status: {
      status: STATUS,
      error_message?: string,
    },
    results: any,
  };

  useEffect(() => {
    if(initializingData.current === false && 
      fetchInterval.current === null &&
      serviceId !== null && 
      computeState === 'PROCESSING') {
      const checker = () => {
        if(isFetching.current === true) return;
        isFetching.current = true;
        axios.get(baseUrl + '/calculate/' + serviceId)
          .then(response => {
            isFetching.current = false;
            const res : Response = response.data;
            if(['SUCCESS', 'ERROR'].includes(res.status.status)) {
              if (fetchInterval.current !== null)
                clearInterval(fetchInterval.current);
              isFetching.current = false;
              setComputeState(res.status.status);
              const compResult = res.results;
              setComputeResult(compResult);
              if (computeResultInitial === null) {
                setComputeResultInitial(compResult);
              }
              let newResult : Result = {...result};
              Object.keys(financialValues).forEach((assetTitle, idx) => {
                const data = result[assetTitle];
                if(!data) return;
                newResult[assetTitle].amount = 
                  compResult.target_scenario.components[idx].produced_energy
                    .map((pe: {amount:number}) => pe.amount)
                    .reduce((accumulator:number, currentValue:number) => accumulator + currentValue, 0);
                (['power', 'capacity', 'area'] as propertyType[]).forEach(property => {
                  const propertyData = data[property];
                  if(!propertyData) return;
                  const propName = 'installed_' + property;
                  newResult[assetTitle][property]!.target = 
                    compResult.target_scenario.components[idx][propName];
                });
              });
              setResult(newResult);
              setValueMain(1);
            }
          })
          .catch((error: AxiosError) => {
            isFetching.current = false;
          })
      }
      checker();
      fetchInterval.current = setInterval(checker, 1000);
      return () => {
        if (fetchInterval.current !== null)
          clearInterval(fetchInterval.current);
      };
    }
  }, [serviceId, computeState, result, financialValues]);

  useEffect(() => {
    if(fetchIntervalOnlyPurchase.current === null &&
      serviceIdOnlyPurchase !== null && 
      computeStateOnlyPurchase === 'PROCESSING') {
      const checker = () => {
        if(isFetchingOnlyPurchase.current === true) return;
        isFetchingOnlyPurchase.current = true;
        axios.get(baseUrl + '/calculate/' + serviceIdOnlyPurchase)
          .then(response => {
            isFetchingOnlyPurchase.current = false;
            const res : Response = response.data;
            if(['SUCCESS', 'ERROR'].includes(res.status.status)) {
              if (fetchIntervalOnlyPurchase.current !== null)
                clearInterval(fetchIntervalOnlyPurchase.current);
              isFetchingOnlyPurchase.current = false;
              setComputeStateOnlyPurchase(res.status.status);
              const compResult = res.results;
              setComputeResultOnlyPurchase(compResult);
            }
          })
          .catch((error: AxiosError) => {
            isFetchingOnlyPurchase.current = false;
          })
      }
      checker();
      fetchIntervalOnlyPurchase.current = setInterval(checker, 1000);
      return () => {
        if (fetchIntervalOnlyPurchase.current !== null)
          clearInterval(fetchIntervalOnlyPurchase.current);
      };
    }
  }, [serviceIdOnlyPurchase, computeStateOnlyPurchase]);

  useEffect(() => {
    if(fetchIntervalNoRestrictions.current === null &&
      serviceIdNoRestrictions !== null && 
      computeStateNoRestrictions === 'PROCESSING') {
      const checker = () => {
        if(isFetchingNoRestrictions.current === true) return;
        isFetchingNoRestrictions.current = true;
        axios.get(baseUrl + '/calculate/' + serviceIdNoRestrictions)
          .then(response => {
            isFetchingNoRestrictions.current = false;
            const res : Response = response.data;
            if(['SUCCESS', 'ERROR'].includes(res.status.status)) {
              if (fetchIntervalNoRestrictions.current !== null)
                clearInterval(fetchIntervalNoRestrictions.current);
              isFetchingNoRestrictions.current = false;
              setComputeStateNoRestrictions(res.status.status);
              const compResult = res.results;
              setComputeResultNoRestrictions(compResult);
            }
          })
          .catch((error: AxiosError) => {
            isFetchingNoRestrictions.current = false;
          })
      }
      checker();
      fetchIntervalNoRestrictions.current = setInterval(checker, 1000);
      return () => {
        if (fetchIntervalNoRestrictions.current !== null)
          clearInterval(fetchIntervalNoRestrictions.current);
      };
    }
  }, [serviceIdNoRestrictions, computeStateNoRestrictions]);

  function newSession() {
    const newSessionId = uuidv4();
    navigate('?sessionId='+newSessionId, { replace: true });
    setSessionId(newSessionId);
    resetState();
  }

  const stateVariables = [
    ['serviceId', serviceId, setServiceId, false],
    ['serviceIdOnlyPurchase', serviceIdOnlyPurchase, setServiceIdOnlyPurchase, false],
    ['serviceIdNoRestrictions', serviceIdNoRestrictions, setServiceIdNoRestrictions, false],
    ['computeState', computeState, setComputeState, false],
    ['computeStateOnlyPurchase', computeStateOnlyPurchase, setComputeStateOnlyPurchase, false],
    ['computeStateNoRestrictions', computeStateNoRestrictions, setComputeStateNoRestrictions, false],
    ['computeResult', computeResult, setComputeResult, false],
    ['computeResultInitial', computeResultInitial, setComputeResultInitial, false],    
    ['computeResultOnlyPurchase', computeResultOnlyPurchase, setComputeResultOnlyPurchase, false],
    ['computeResultNoRestrictions', computeResultNoRestrictions, setComputeResultNoRestrictions, false],
    ['result', result, setResult, false],
    ['location', location, setLocation, false],
    ['longitude', longitude, setLongitude, false],
    ['latitude', latitude, setLatitude, false],
    ['timeSeriesResolution', timeSeriesResolution, setTimeSeriesResolution, false],
    ['timeSeriesValues', timeSeriesValues, setTimeSeriesValues, false],
    ['timeSeriesErrors', timeSeriesErrors, setTimeSeriesErrors, false],
    ['assetValues', assetValues, setAssetValues, false],
    ['assetErrors', assetErrors, setAssetErrors, false],
    ['assetExcludes', assetExcludes, setAssetExcludes, false],
    ['assetExcludesErrors', assetExcludesErrors, setAssetExcludesErrors, false],
    ['energyTariffsPriceForPower', energyTariffsPriceForPower, setEnergyTariffsPriceForPower, false],
    ['energyTariffsPriceForPowerErrors', energyTariffsPriceForPowerErrors, setEnergyTariffsPriceForPowerErrors, false],
    ['financialValues', financialValues, setFinancialValues, true],
    ['financialValuesErrors', financialValuesErrors, setFinancialValuesErrors, true],
    ['interestRate', interestRate, setInterestRate, true],
  ]

  function resetState() {
    setServiceId(null);
    setServiceIdOnlyPurchase(null);
    setServiceIdNoRestrictions(null);
    setComputeState('NEW');
    setComputeStateOnlyPurchase('NEW');
    setComputeStateNoRestrictions('NEW');
    setComputeResult(null);
    setComputeResultInitial(null);
    setComputeResultOnlyPurchase(null);
    setComputeResultNoRestrictions(null);
    setResult({});
    setLocation('');
    setLongitude(null);
    setLatitude(null);
    setTimeSeriesResolution(DEFAULT_VALUES.timeSeriesResolution);
    setTimeSeriesValues(DEFAULT_VALUES.timeSeriesValues);
    setTimeSeriesErrors({});
    setAssetValues({});
    setAssetErrors({});
    setAssetExcludes(deltaAssetExcludes(DEFAULT_VALUES.financialValues, {}));
    setAssetExcludesErrors({});
    setEnergyTariffsPriceForPower(DEFAULT_VALUES.energyTariffsPriceForPower);
    setEnergyTariffsPriceForPowerErrors({});
    setFinancialValues(DEFAULT_VALUES.financialValues);
    setFinancialValuesErrors({});
    setInterestRate(DEFAULT_VALUES.interestRate);
  }

  async function refreshState(sessionId: string) {
    const globalData = await getByID(GLOBAL_SESSION_ID);
    const globalCurrentPayloadRaw = globalData as {id: string, data: any};
    if(!globalCurrentPayloadRaw) return;
    const globalCurrentData = globalCurrentPayloadRaw.data;
    stateVariables.filter(sv => sv[3]).forEach(stateVariable => {
      const variableName = stateVariable[0] as string;
      const variableSetter = stateVariable[2] as React.Dispatch<SetStateAction<any>>;
      if (globalCurrentData[variableName] !== undefined)
        variableSetter(globalCurrentData[variableName]);
    });

    if(!sessionId) return;
    const sessionData = await getByID(sessionId);
    const currentPayloadRaw = sessionData as {id: string, data: any};
    if(!currentPayloadRaw) return;
    const currentData = currentPayloadRaw.data;
    stateVariables.filter(sv => !sv[3]).forEach(stateVariable => {
      const variableName = stateVariable[0] as string;
      const variableSetter = stateVariable[2] as React.Dispatch<SetStateAction<any>>;
      if (currentData[variableName] !== undefined) {
        variableSetter(currentData[variableName]);
      }
    });
  }

  async function saveState(sessionId: string) {
    if (initializingData.current !== false) return;
    const sessionData = await getByID(sessionId);
    const currentPayloadRaw = sessionData as {id: string, data: any};
    const currentData : any = currentPayloadRaw ? currentPayloadRaw.data : {};
    stateVariables.filter(sv => !sv[3]).forEach(stateVariable => {
      const variableName = stateVariable[0] as string;
      currentData[variableName] = stateVariable[1];
    });
    await update({ 
      sessionId, 
      data: currentData,
    });

    const globalData = await getByID(GLOBAL_SESSION_ID);
    const globalCurrentPayloadRaw = globalData as {id: string, data: any};
    const globalCurrentData : any = globalCurrentPayloadRaw ? globalCurrentPayloadRaw.data : {};
    stateVariables.filter(sv => sv[3]).forEach(stateVariable => {
      const variableName = stateVariable[0] as string;
      globalCurrentData[variableName] = stateVariable[1];
    });
    await update({ 
      sessionId: GLOBAL_SESSION_ID, 
      data: globalCurrentData,
    });
  }

  useEffect(() => {
    (async () => {
      if (sessionId) {
        await saveState(sessionId);
      }
      setRequestDataValid(getRequestData('current') !== null);
    })();
  }, [sessionId,
    serviceId,
    serviceIdOnlyPurchase,
    serviceIdNoRestrictions,
    computeState,
    computeStateOnlyPurchase,
    computeStateNoRestrictions,    
    computeResult,
    computeResultInitial,
    computeResultOnlyPurchase,
    computeResultNoRestrictions,
    result,
    location,
    longitude,
    latitude,
    timeSeriesResolution,
    timeSeriesValues,
    timeSeriesErrors,
    assetValues,
    assetErrors,
    assetExcludes,
    assetExcludesErrors,
    energyTariffsPriceForPower,
    energyTariffsPriceForPowerErrors,
    financialValues,
    financialValuesErrors,
    interestRate]);

  interface EnergyTariff {
    energy: {
      price_values: number[],
      resolution: resolutionStep,
      unit: 'EUR_PER_KWH',
    },
    power: number,
  };

  interface group1 {
    component_type: 'PHOTOVOLTAIK_ROOF' | 'PHOTOVOLTAIK_FREE_FIELD' | 'WIND_POWER' | 'COMBINED_HEAT_POWER',
    installed_power: number, // 0 not installed
    potential_power?: number, // undefined => no limit, 0 exclude
    capex: number,
    opex: number,
    lifespan: number,
  };

  interface group2 {
    component_type: 'SOLARTHERMAL_ENERGY',
    installed_area: number, // 0 not installed
    potential_area?: number, // undefined => no limit, 0 exclude
    capex: number,
    opex: number,
    lifespan: number,
  };

  interface group3 {
    component_type: 'ELECTRICAL_ENERGY_STORAGE' | 'THERMAL_ENERGY_STORAGE',
    installed_power: number, // 0 not installed
    potential_power?: number, // undefined => no limit, 0 exclude
    installed_capacity: number, // 0 not installed
    potential_capacity?: number, // undefined => no limit, 0 exclude
    capex_power: number,
    capex_capacity: number,
    opex: number,
    lifespan: number,
  };

  interface group4 {
    component_type: 'HEAT_PUMP_AIR' | 'HEAT_PUMP_GROUND' | 'GAS_BOILER',
    installed_power: number, // 0 not installed
    potential_power?: number, // undefined => no limit, 0 exclude
    capex: number,
    opex: number,
    lifespan: number,
  };

  interface EnergyDemandItem {
    demand_values: number[],
    resolution: resolutionStep,
    unit: 'KWH',
    energy_type: 'ELECTRICAL' | 'THERMAL' | 'NATURAL_GAS',
  };

  interface Request {
    location: {
      longitude: number,
      latitude: number,
    },
    interest_rate: number,
    energy_demands: EnergyDemandItem[],
    energy_components: (group1 | group2 | group3 | group4)[],
    energy_tariffs: {
      electrical_energy_purchase?: EnergyTariff,
      electrical_energy_feedin?: EnergyTariff,
      thermal_energy_purchase?: EnergyTariff,
      natural_gas_purchase?: EnergyTariff,
    },
  };

  interface ResultViewProp {
    is: number,
    target: number | null,
    targetMode: boolean,
    max: number | null,
  };

  interface ResultView {
    power: null | ResultViewProp,
    capacity: null | ResultViewProp,
    area: null | ResultViewProp,
    amount: number | null,
  };

  type Result = Record<string, ResultView>;

  function getViewData(assetTitle: string, properties: propertyType[]) {

    let viewData: ResultView = {
      power : null,
      capacity: null,
      area: null,
      amount: 0
    };

    properties.forEach(property => {

      viewData[property] = {
        is: 0,
        target: null,
        targetMode: false,         
        max: null,
      };

      viewData[property]!.is = Object.values(assetValues)
        .filter(av => av.assetFinancial === assetTitle)
        .map(av => av.config[property])
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      const item = assetExcludes[assetTitle];
      if (!item) return;

      if(['NO_BUILD', 'DECONSTRUCT'].includes(item.select)) {
        viewData[property]!.target = 0;
        viewData[property]!.max = 0;
      }
      else if(item.select === 'CUSTOM') {
        if (item.customExcludes[property]! === 0) {
          viewData[property]!.target = 0;
          viewData[property]!.max = 0;
        } else {
          viewData[property]!.max = item.customExcludes[property]!;
        }
      }
    });

    return viewData;
  }

  useEffect(() => {
    if(initializingData.current !== false) return;
    if(computeResult !== null) return;
    let newResult : Result = {};
    Object.keys(financialValues).forEach(assetTitle => {
      const data = financialValues[assetTitle];
      if(data === undefined) return;
      const assetType = data.assetType as assetType;

      if(['PHOTOVOLTAIK_ROOF', 'PHOTOVOLTAIK_FREE_FIELD', 'WIND_POWER', 'COMBINED_HEAT_POWER', 
          'HEAT_PUMP_AIR', 'HEAT_PUMP_GROUND', 'GAS_BOILER'].includes(assetType)) {
        newResult[assetTitle] = getViewData(assetTitle, ['power'])
      } else if(['SOLARTHERMAL_ENERGY'].includes(assetType)) {
        newResult[assetTitle] = getViewData(assetTitle, ['area'])
      } else if(['ELECTRICAL_ENERGY_STORAGE', 'THERMAL_ENERGY_STORAGE'].includes(assetType)) {
        newResult[assetTitle] = getViewData(assetTitle, ['power', 'capacity'])
      }
    });
    if (JSON.stringify(result) !== JSON.stringify(newResult))
      setResult(newResult);
  }, [assetValues,
    assetExcludes,
    financialValues,
    result]);

  interface InstalledAndPotentialType { 
    installed_power: number | null,
    installed_capacity: number | null,
    installed_area: number | null,
    potential_power: number | null,
    potential_capacity: number | null,
    potential_area: number | null,
  }

  function getInstalledAndPotential(assetTitle: string, type: 'current' | 'onlyPurchase' | 'noRestrictions') {

    let apiData : InstalledAndPotentialType = {
      installed_power: null,
      installed_capacity: null,
      installed_area: null,
      potential_power: null,
      potential_capacity: null,
      potential_area: null,
    };

    const item = result[assetTitle];
    if (!item) return apiData;

    (['power', 'capacity', 'area'] as propertyType[]).forEach(property => {
      if (item[property] === null) return;

      if (type === 'onlyPurchase') {
        apiData['installed_' + property as keyof InstalledAndPotentialType] = 0;
        apiData['potential_' + property as keyof InstalledAndPotentialType] = 0;

      } else if (type === 'noRestrictions') {
        apiData['installed_' + property as keyof InstalledAndPotentialType] = item[property]!.is;

      } else {
        if (item[property]!.targetMode) {
          apiData['installed_' + property as keyof InstalledAndPotentialType] = item[property]!.target!;
          apiData['potential_' + property as keyof InstalledAndPotentialType] = item[property]!.target!;
        } else {
          apiData['installed_' + property as keyof InstalledAndPotentialType] = Math.min(item[property]!.is, item[property]!.max ?? item[property]!.is);
          if (item[property]!.max !== null)
            apiData['potential_' + property as keyof InstalledAndPotentialType] = item[property]!.max;
        }
      }
    });

    return apiData;
  }

  interface CapexAndOpexType1 { 
    capex: number,
    opex: number,
  }

  interface CapexAndOpexType2 { 
    capex_power: number,
    capex_capacity: number,
    opex: number,
  }

  function getCapexAndOpex(assetTitle: string, assetType: assetType) {

    const financialValue = financialValues[assetTitle]!;

    if(['ELECTRICAL_ENERGY_STORAGE', 'THERMAL_ENERGY_STORAGE'].includes(assetType)) {
      return {
        capex_power: financialValue.config.power!.capex,
        capex_capacity: financialValue.config.capacity!.capex,
        opex: financialValue.config.power!.opex, // todo: should actually differentiate between opex_power and opex_capacity
      } as CapexAndOpexType2;
    } else {
      const financialValueConfig = financialValue.config.power !== null ? financialValue.config.power : 
        (financialValue.config.capacity !== null ? financialValue.config.capacity : financialValue.config.area);
      return {
        capex: financialValueConfig!.capex,
        opex: financialValueConfig!.opex,
      } as CapexAndOpexType1
    }
  }

  // prepare request for rest endpoint
  function getRequestData(type: 'current' | 'onlyPurchase' | 'noRestrictions') {
    if(longitude === null || latitude === null ||
       (timeSeriesValues.electricityConsumption.length === 0 && 
        timeSeriesValues.heatConsumption.length === 0 && 
        timeSeriesValues.gasConsumption.length === 0))
      return null;

    const energy_demands : Request['energy_demands'] = (() => {
      const result : EnergyDemandItem[] = [];
      (['electricityConsumption', 'heatConsumption', 'gasConsumption'] as 
          timeSeriesConsumptionType[]).map(timeSeriesConsumptionType => {
        if(timeSeriesValues[timeSeriesConsumptionType].length > 0) {
          result.push({
            demand_values: timeSeriesValues[timeSeriesConsumptionType],
            resolution: timeSeriesResolution[timeSeriesConsumptionType],
            unit: 'KWH',
            energy_type: (() => {
              switch (timeSeriesConsumptionType) {
                case 'electricityConsumption':
                  return 'ELECTRICAL';
                  break;
                case 'heatConsumption':
                  return 'THERMAL';
                  break;
                case 'gasConsumption':
                  return 'NATURAL_GAS';
                  break;
              }
            })(),
          });
        }
      });
      return result as Request['energy_demands'];
    })();
    
    const energy_components : Request['energy_components'] = (() => {
      return (Object.keys(financialValues) as string[]).map(assetTitle => {
        const data = financialValues[assetTitle];
        if(data === undefined) return;
        const assetType = data.assetType as assetType;
        const financialConfig = data.config;

        const v = {
          component_type: data.assetType,
          lifespan: financialConfig.lifespan ?? DEFAULT_VALUES.lifespan,
        };

        return {
          ...(() => {
            if(['PHOTOVOLTAIK_ROOF', 'PHOTOVOLTAIK_FREE_FIELD', 'WIND_POWER', 'COMBINED_HEAT_POWER'].includes(assetType)) {
              return {
                ...v,
                component_type: assetType as group1['component_type'],
                ...getInstalledAndPotential(assetTitle, type),
                ...getCapexAndOpex(assetTitle, assetType),
              } as group1;
            } else if(['SOLARTHERMAL_ENERGY'].includes(assetType)) {
              return {
                ...v,
                component_type: assetType as group2['component_type'],
                ...getInstalledAndPotential(assetTitle, type),
                ...getCapexAndOpex(assetTitle, assetType),
              } as group2;
            } else if(['ELECTRICAL_ENERGY_STORAGE', 'THERMAL_ENERGY_STORAGE'].includes(assetType)) {
              return {
                ...v,
                component_type: assetType as group3['component_type'],
                ...getInstalledAndPotential(assetTitle, type),
                ...getCapexAndOpex(assetTitle, assetType),
              } as group3;
            } else if(['HEAT_PUMP_AIR', 'HEAT_PUMP_GROUND', 'GAS_BOILER'].includes(assetType)) {
              return {
                ...v,
                component_type: assetType as group4['component_type'],
                ...getInstalledAndPotential(assetTitle, type),
                ...getCapexAndOpex(assetTitle, assetType),
              } as group4;
            } else {
              return {};
            }
          })(),
        };
      }) as Request['energy_components'];
    })();

    const camelToSnakeCase = (str : string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

    const energy_tariffs : Request['energy_tariffs'] = {};
    (['electricalEnergyPurchase', 'electricalEnergyFeedin', 'thermalEnergyPurchase', 'naturalGasPurchase'] as 
        timeSeriesPurchaseType[]).map(timeSeriesPurchaseType => {
      if (timeSeriesValues[timeSeriesPurchaseType].length > 0) {
        const key = camelToSnakeCase(timeSeriesPurchaseType) as 'electrical_energy_purchase' | 'electrical_energy_feedin' | 'thermal_energy_purchase' | 'natural_gas_purchase';
        energy_tariffs[key] = {
          energy: {
            price_values: timeSeriesValues[timeSeriesPurchaseType].map(v => v * (key === 'electrical_energy_feedin' ? -1 : 1)),
            resolution: timeSeriesResolution[timeSeriesPurchaseType],
            unit: 'EUR_PER_KWH',
          },
          power: energyTariffsPriceForPower[timeSeriesPurchaseType],
        };
      }
    })

    const request : Request = {
      location: {
        longitude: longitude,
        latitude: latitude,
      },
      interest_rate: interestRate,
      energy_demands,
      energy_components,
      energy_tariffs,
    };

    return request;
  }

  function computeSession() {
    if(sessionId === null) return;
    const requestData = getRequestData('current');
    if (requestData === null) {
      console.debug('Request data invalid');
      return;
    }  
    setComputeState('PROCESSING');
    axios.post(baseUrl + '/calculate', requestData)
      .then(response => {
        setServiceId(response.data.calculation_id);
      })
      .catch((error: AxiosError) => {
        console.log(error);
        setComputeState('ERROR');
      })

    // initially compute alternatives:
    // - only energy purchase via tariffs
    // - no restrictions
    if (computeResultInitial === null) {
      setTimeout(() => {
        setComputeStateOnlyPurchase('PROCESSING');
        axios.post(baseUrl + '/calculate', getRequestData('onlyPurchase'))
          .then(response => {
            setServiceIdOnlyPurchase(response.data.calculation_id);
          })
          .catch((error: AxiosError) => {
            setComputeStateOnlyPurchase('ERROR');
          })
      }, 1000);

      setTimeout(() => {
        setComputeStateNoRestrictions('PROCESSING');
        axios.post(baseUrl + '/calculate', getRequestData('noRestrictions'))
          .then(response => {
            setServiceIdNoRestrictions(response.data.calculation_id);
          })
          .catch((error: AxiosError) => {
            setComputeStateNoRestrictions('ERROR');
          })
       }, 2000);
    } 
  }

  function processFile(e: React.ChangeEvent<HTMLInputElement>, type: timeSeriesType) {
    const reader = new FileReader();
    reader.onload = async (e) => { 
      setTimeSeriesErrors(ce => {
        const newCe = {...ce}; 
        delete newCe[type]; 
        return newCe;});
      const text : null | string | ArrayBuffer = reader.result;
      if (typeof text === 'string' || text instanceof String) {
        const lines = text.split(/\r?\n/);
        const targetSize = resolutions[timeSeriesResolution[type]];
        if (lines.length !== targetSize) {
          setTimeSeriesErrors(ce => {
            const newCe = {...ce};
            newCe[type] = targetSize + ' Verbrauchswerte erforderlich';
            return newCe;
          });
          return;
        }
        const newConsumptionValues : number[] = [];
        lines.forEach((line) => {
          const i = parseInt(line);
          if(isNaN(i)) {
            setTimeSeriesErrors(ce => {
              const newCe = {...ce};
              newCe[type] = 'Es werden nur ganzzahlige Eingaben akzeptiert';
              return newCe;
            });
            return;
          }
          if(i<0) {               
            setTimeSeriesErrors(ce => {
              const newCe = {...ce};
              newCe[type] = 'Es werden nur positive Nummern akzeptiert';
              return newCe;
            })
            return;
          }
          newConsumptionValues.push(i);
        });
        setTimeSeriesValues(cv => {
          const newCv = {...cv};
          newCv[type] = newConsumptionValues;
          return newCv;
        })
      } else {
        setTimeSeriesErrors(ce => {
          const newCe = {...ce};
          newCe[type] = 'Interner Fehler bei der Verarbeitung der Datei';
          return newCe;
        })
      }
    };
    if (e.target.files !== null && e.target.files.length > 0)
      reader.readAsText(e.target.files[0]);
  }

  function timeSeriesTypeReadable(type: timeSeriesType) {
    switch (type) {
      case 'electricityConsumption':
        return 'Strombedarf';
        break;
      case 'heatConsumption':
        return 'Wärmebedarf';
        break;
      case 'gasConsumption':
        return 'Erdgasbedarf';
        break;
      case 'electricalEnergyPurchase':
        return 'Stromliefervertrag';
        break;
      case 'electricalEnergyFeedin':
        return 'Überschüsseinspeisung';
        break;
      case 'thermalEnergyPurchase':
        return 'Wärmeliefervertrag';
        break;
      case 'naturalGasPurchase':
        return 'Erdgasliefervertrag';
        break;
    }
  }

  function timeSeriesTypeUnitReadable(type: timeSeriesType) {
    switch (type) {
      case 'electricityConsumption':
        return 'kWh';
        break;
      case 'heatConsumption':
        return 'kWh';
        break;
      case 'gasConsumption':
        return 'kWh';
        break;
      case 'electricalEnergyPurchase':
        return 'EUR/kWh';
        break;
      case 'electricalEnergyFeedin':
        return 'EUR/kWh';
        break;
      case 'thermalEnergyPurchase':
        return 'EUR/kWh';
        break;
      case 'naturalGasPurchase':
        return 'EUR/kWh';
        break;
    }
  }

  function propertyTypeReadable(type: propertyType) {
    switch (type) {
      case 'power':
        return 'Leistung (kW)';
        break;
      case 'capacity':
        return 'Menge (kWh)';
        break;
      case 'area':
        return 'Fläche (m^2)';
        break;
    }
  }

  function financialPropertyTypeReadable(type: financialPropertyType) {
    switch (type) {
      case 'capex':
        return 'EUR/kW/kWH/m^2';
        break;
      case 'opex':
        return 'EUR/Jahr';
        break;
      case 'lifespan':
        return 'Jahre';
        break;
    }
  }

  function statusReadable(status: STATUS) {
    switch (status) {
      case 'NEW':
        return 'Dateneingabe';
        break;
      case 'UPDATED':
        return 'Daten aktualisiert';
        break;
      case 'PROCESSING':
        return 'Wird berechnet...';
        break;
      case 'SUCCESS':
        return 'Erfolgreich berechnet';
        break;
      case 'ERROR':
        return 'Fehler bei Berechnung';
        break;
    }
  }

  function tariffReadable(tariff: string) {
    switch (tariff) {
      case 'ELECTRICAL_ENERGY_PURCHASE':
        return 'Stromliefervertrag';
        break;
      case 'ELECTRICAL_ENERGY_FEEDIN':
        return 'Einspeisvergütung';
        break;
      case 'NATURAL_GAS_PURCHASE':
        return 'Erdgasliefervertrag';
        break;
      case 'THERMAL_ENERGY_PURCHASE':
        return 'Wärmeliefervertrag';
        break;
    }
  }

  const AssetContentCurrent = ({tabStateOwner}: {
      tabStateOwner: [number, React.Dispatch<React.SetStateAction<number>>]
    }) => {

    const [value, setValue] = tabStateOwner;
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return <Box>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Primärenergie-Erzeugungsanlagen" {...a11yProps(0)} />
              <Tab label="Speicher" {...a11yProps(1)} />
              <Tab label="Energieumwandlungsanlagen" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {assetConfigurationCurrent('primaryGeneration')}
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            {assetConfigurationCurrent('storage')}
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            {assetConfigurationCurrent('converter')}
          </CustomTabPanel>
        </Box>
      </Box>;
  };

  function assetConfigurationCurrent(group: string) {
    return <>
      <Box sx={{my:3}}>
        <Stack direction='column' sx={{mt:3}}>
          Neue Anlage:
          <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2} sx={{my:2}}>
            <FormControl sx={{width:400}}>
              <InputLabel id={'asset-select-label-' + group}>Anlagen-Typ</InputLabel>
              <Select
                id={'asset-select-' + group}
                labelId={'asset-select-label-' + group}
                value={addAssetType ?? ''}
                label='Anlagen-Typ'
                onChange={e => setAddAssetType(e.target.value as assetType)}
              >
                {(Object.keys(assetConfigs) as assetType[])
                  .map(r => <MenuItem key={r} value={r}>{assetConfigs[r].title}</MenuItem>)}
              </Select>
            </FormControl>
            <TextField
              id={'asset-financial-data-name'}
              label={'Anlagen-Bezeichnung'}
              value={addAssetName ?? ''}
              sx={{width:400}}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setAddAssetName(null);
                  return;
                }
                setAddAssetName(event.target.value);
              }}
              helperText={addAssetName !== null && addAssetName !== '' && addAssetName in assetValues ? 
                'Anlagen-Bezeichnung bereits vergeben' : null}
              error={addAssetName !== null && addAssetName !== '' && addAssetName in assetValues}
            />
            <IconButton 
              id={'asset-add-' + group} 
              aria-label="add" 
              disabled={addAssetType === null || addAssetName === null || 
                addAssetName === '' || addAssetName in assetValues}
              onClick={() => {
                if (addAssetType === null || addAssetName === null) return;
                setAssetValues(av => {
                  const newAv = {...av}; 
                  newAv[addAssetName] = {
                    assetType: addAssetType,
                    assetFinancial: Object.entries(financialValues).find(([key, value]) => value!.assetType === addAssetType && value!.default)![0],
                    config: {
                      power: 0, 
                      capacity: 0, 
                      area: 0,
                    },
                  };
                  setAddAssetType(null);
                  return newAv;
                })
              }}>
              <AddIcon/>
            </IconButton>
          </Stack>
        </Stack>
        <TableContainer component={Paper} sx={{mt:3}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Anlagen-Bezeichnung</TableCell>
                <TableCell align="center">Anlagen-Typ</TableCell>
                <TableCell align="center">Parameter</TableCell>
                <TableCell align="center">Anlagen-Finanzdaten</TableCell>
                <TableCell align="right">Entfernen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {Object.entries(assetValues)
              .filter(([key, value]) => assetConfigs[value!.assetType].group === group)
              .map(([assetTitle, data]) => {
                if(!data) return;
                const assetType = data.assetType as assetType;
                const assetFinancial = data.assetFinancial;
                const config = data.config;
                return <TableRow
                  key={assetTitle}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left" component="th" scope="row">
                    {assetTitle}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {assetConfigs[assetType].title}
                  </TableCell>
                  <TableCell align="center">{(['power', 'capacity', 'area'] as propertyType[])
                      .filter(property => assetConfigs[assetType][property] === true)
                      .map(property => 
                    <TextField
                      key={type + '-' + property}
                      id={type + '-' + property}
                      label={propertyTypeReadable(property)}
                      value={config[property]}
                      sx={{ml:3,width:'400px'}}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setAssetErrors(ce => {
                          const newCe = {...ce}; 
                          if (newCe[assetTitle] !== undefined)
                            delete newCe[assetTitle]![property]; 
                          return newCe;});
                        if (event.target.value === '') {
                          setAssetValues(cv => {
                            const newCv = {...cv}; 
                            newCv[assetTitle].config[property] = 0; 
                            return newCv;})
                          return;
                        }
                        const i = parseInt(event.target.value);
                        if(isNaN(i)) {
                          setAssetErrors(ce => {
                            const newCe = {...ce}; 
                            newCe[assetTitle] = newCe[assetTitle] === undefined ? {} : newCe[assetTitle];
                            newCe[assetTitle]![property] = 'Es werden nur ganzzahlige Eingaben akzeptiert'; 
                            return newCe;});
                          return;
                        }
                        setAssetValues(cv => {
                          const newCv = {...cv}; 
                          newCv[assetTitle].config[property] = i; 
                          return newCv;
                        })
                      }}
                      helperText={assetErrors[assetTitle] !== undefined ? assetErrors[assetTitle]![property] : undefined}
                      error={assetErrors[assetTitle] !== undefined && !!assetErrors[assetTitle]![property]}
                    />)}
                  </TableCell>
                  <TableCell align="center">
                    <FormControl sx={{width:400}}>
                      <InputLabel id={'asset-select-label-' + group}>Anlagen-Finanzdaten</InputLabel>
                      <Select
                        id={'asset-select-' + group}
                        labelId={'asset-select-label-' + group}
                        value={assetFinancial}
                        label='Anlagen-Finanzdaten'
                        onChange={e => 
                          setAssetValues(cv => {
                            const newCv = {...cv}; 
                            newCv[assetTitle].assetFinancial = e.target.value; 
                            return newCv;
                          })
                        }
                      >
                        {(Object.entries(financialValues)
                          .filter(([key, value]) => value!.assetType === assetType))
                          .map(([key, value]) => key)
                          .map(r => <MenuItem key={r} value={r}>{r}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="right">{<IconButton aria-label="delete" onClick={() => {
                      setAssetErrors(ce => {
                        const newCe = {...ce}; 
                        delete newCe[assetTitle]; 
                        return newCe;});
                      setAssetValues(cv => {
                        const newCv = {...cv}; 
                        delete newCv[assetTitle]; 
                        return newCv;})
                    }}>
                    <DeleteIcon/>
                  </IconButton>}</TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>;
  }

  const AssetContentRestrictions = ({tabStateOwner}: {
      tabStateOwner: [number, React.Dispatch<React.SetStateAction<number>>]
    }) => {

    const [value, setValue] = tabStateOwner;
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return <Box>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Primärenergie-Erzeugungsanlagen" {...a11yProps(0)} />
              <Tab label="Speicher" {...a11yProps(1)} />
              <Tab label="Energieumwandlungsanlagen" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {assetConfigurationRestrictions('primaryGeneration')}
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            {assetConfigurationRestrictions('storage')}
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            {assetConfigurationRestrictions('converter')}
          </CustomTabPanel>
        </Box>
      </Box>;
  };

  function assetConfigurationRestrictions(group: string) {
    return <>
      <Box sx={{my:3}}>
        Beschränkungen definieren:
        <TableContainer component={Paper} sx={{mt:3}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Anlagen-Bezeichnung</TableCell>
                <TableCell align="center">Anlagen-Typ</TableCell>
                <TableCell align="center">Keine Beschränkung</TableCell>
                <TableCell align="center">Nicht erweitern/aufbauen</TableCell>
                <TableCell align="center">Abbauen</TableCell>
                <TableCell align="right">Benutzerdefinierte Beschränkung</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(assetExcludes)
                .filter(([key, value]) => financialValues[key] !== undefined && assetConfigs[financialValues[key]!.assetType].group === group)
                .map(([assetTitle, data]) => {
                  if(!data) return;
                  const assetType = financialValues[assetTitle]!.assetType as assetType;
                  return <TableRow
                    key={assetTitle}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left" component="th" scope="row">
                      {assetTitle}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {assetConfigs[assetType].title}
                    </TableCell>
                    <TableCell align="center">{
                      <Checkbox checked={assetExcludes[assetTitle]?.select === 'UNLIMITED'} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setAssetExcludesErrors(ce => {
                          const newCe = {...ce}; 
                          delete newCe[assetTitle]; 
                          return newCe;});
                        setAssetExcludes(cv => {
                          const newCv = {...cv}; 
                          newCv[assetTitle]!.select = 'UNLIMITED'; 
                          newCv[assetTitle]!.customExcludes = {power: null, capacity: null, area: null};
                          return newCv;})
                      }}/>}</TableCell>
                    <TableCell align="center">{
                      <Checkbox checked={assetExcludes[assetTitle]?.select === 'NO_BUILD'} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setAssetExcludesErrors(ce => {
                          const newCe = {...ce}; 
                          delete newCe[assetTitle]; 
                          return newCe;});
                        setAssetExcludes(cv => {
                          const newCv = {...cv}; 
                          newCv[assetTitle]!.select = 'NO_BUILD'; 
                          newCv[assetTitle]!.customExcludes = {power: null, capacity: null, area: null};
                          return newCv;})
                      }}/>}</TableCell>
                    <TableCell align="center">{
                      <Checkbox checked={assetExcludes[assetTitle]?.select === 'DECONSTRUCT'} disabled={assetValues[assetTitle] === undefined} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setAssetExcludesErrors(ce => {
                          const newCe = {...ce}; 
                          delete newCe[assetTitle]; 
                          return newCe;});
                        setAssetExcludes(cv => {
                          const newCv = {...cv}; 
                          newCv[assetTitle]!.select = 'DECONSTRUCT'; 
                          newCv[assetTitle]!.customExcludes = {power: null, capacity: null, area: null};
                          return newCv;})
                      }}/>}</TableCell>
                    <TableCell align="right">{<>
                      <Checkbox checked={assetExcludes[assetTitle]?.select === 'CUSTOM'} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setAssetExcludes(cv => {
                          const newCv = {...cv}; 
                          newCv[assetTitle]!.select = 'CUSTOM'; 
                          return newCv;})
                      }}/>
                      <>
                      {(['power', 'capacity', 'area'] as propertyType[])
                          .filter(property => assetConfigs[assetType][property] === true)
                          .map(property =>
                        <TextField
                          key={type + '-' + property}
                          id={type + '-' + property}
                          label={'Maximale ' + propertyTypeReadable(property)}
                          value={assetExcludes[assetTitle]!.customExcludes[property] ?? ''}
                          sx={{ml:3,width:'400px'}}
                          disabled={assetExcludes[assetTitle]?.select !== 'CUSTOM'}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setAssetExcludesErrors(ce => {
                              const newCe = {...ce}; 
                              if (newCe[assetTitle] !== undefined)
                                delete newCe[assetTitle]![property]; 
                              return newCe;});
                            if (event.target.value === '') {
                              setAssetExcludes(cv => {
                                const newCv = {...cv}; 
                                newCv[assetTitle]!.customExcludes[property] = null; 
                                return newCv;})
                              return;
                            }
                            const i = parseInt(event.target.value);
                            if(isNaN(i)) {
                              setAssetExcludesErrors(ce => {
                                const newCe = {...ce}; 
                                newCe[assetTitle] = newCe[assetTitle] === undefined ? {} : newCe[assetTitle];
                                newCe[assetTitle]![property] = 'Es werden nur ganzzahlige Eingaben akzeptiert'; 
                                return newCe;});
                              return;
                            }
                            setAssetExcludes(cv => {
                              const newCv = {...cv}; 
                              newCv[assetTitle]!.customExcludes[property] = i; 
                              return newCv;})
                          }}
                          helperText={assetExcludesErrors[assetTitle] !== undefined ? assetExcludesErrors[assetTitle]![property] : undefined}
                          error={assetExcludesErrors[assetTitle] !== undefined && !!assetExcludesErrors[assetTitle]![property]}
                        />)}
                      </>
                    </>}</TableCell>
                  </TableRow>
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>;
  }

  function rowConsumption(type: timeSeriesType) {
    return <TableRow
      key={type}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {timeSeriesTypeReadable(type)}
      </TableCell>
      <TableCell align="right">{
        <FormControl>
          <InputLabel id={type + '-select-label'}>Auflösung</InputLabel>
          <Select
            labelId={type + '-select-label'}
            value={timeSeriesResolution[type]}
            label='Auflösung'
            sx={{width:'100px'}}
            onChange={e => {
              setTimeSeriesErrors(ce => {
                const newCe = {...ce}; 
                delete newCe[type]; 
                return newCe;});
              setTimeSeriesValues(cv => {
                const newCv = {...cv}; 
                newCv[type] = [0]; 
                return newCv;})
              setTimeSeriesResolution(cr => {
                const newCr = {...cr}; 
                newCr[type] = e.target.value as resolutionStep; 
                return newCr;})
            }}
          >
            {resolutionSteps.map(r => <MenuItem key={r} value={r}>{r}</MenuItem>)}
          </Select>
        </FormControl>
      }</TableCell>
      <TableCell align="center">{timeSeriesResolution[type] === '1Y' ?
        <TextField
          id={type}
          label={timeSeriesTypeUnitReadable(type)}
          value={timeSeriesValues[type][0] ?? 0}
          sx={{width:'400px'}}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTimeSeriesErrors(ce => {
              const newCe = {...ce}; 
              delete newCe[type]; 
              return newCe;});
            if (event.target.value === '') {
              setTimeSeriesValues(cv => {
                const newCv = {...cv}; 
                newCv[type] = [0]; 
                return newCv;});
              return;
            }
            const i = parseInt(event.target.value);
            if(isNaN(i)) {
              setTimeSeriesErrors(ce => {
                const newCe = {...ce}; 
                newCe[type] = 'Es werden nur ganzzahlige Eingaben akzeptiert'; 
                return newCe;});
              return;
            }
            setTimeSeriesValues(cv => {
              const newCv = {...cv}; 
              newCv[type] = [i]; 
              return newCv;})
          }}
          helperText={timeSeriesErrors[type]}
          error={!!timeSeriesErrors[type]}
        />
        :
        <Stack direction='column' sx={{width:'400px'}}>
          <Button component='label' variant='contained' startIcon={<CloudUploadIcon />}>
            Upload file
            <VisuallyHiddenInput type='file' onChange={(e) => {
              processFile(e, type)
            }}/>
          </Button>
          {!!timeSeriesErrors[type] &&
            <>
              <br/>
              <Typography variant={'subtitle2'} sx={{mt:-2, color:'red'}}>{timeSeriesErrors[type]}</Typography>
            </>
          }
        </Stack>
      }</TableCell>
      <TableCell align="right">
        <IconButton aria-label="delete" onClick={() => {
          setTimeSeriesValues(ce => {
            const newCe = {...ce}; 
            newCe[type] = []; 
            return newCe;});
          setTimeSeriesResolution(cv => {
            const newCv = {...cv}; 
            newCv[type] = '1Y';
            return newCv;
          });
          setTimeSeriesErrors(cv => {
            const newCv = {...cv}; 
            delete newCv[type];
            return newCv;
          });
        }}>
          <DeleteIcon/>
        </IconButton>
      </TableCell>
    </TableRow>;
  }

  function rowEnergyTariff(type: timeSeriesPurchaseType) {
    return <TableRow
      key={type}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align="left" component="th" scope="row">
        {timeSeriesTypeReadable(type)}
      </TableCell>
      <TableCell align="center">{
        <FormControl>
          <InputLabel id={type + '-select-label'}>Auflösung</InputLabel>
          <Select
            labelId={type + '-select-label'}
            value={timeSeriesResolution[type]}
            label='Auflösung'
            sx={{width:'100px'}}
            onChange={e => {
              setTimeSeriesErrors(ce => {
                const newCe = {...ce}; 
                delete newCe[type]; 
                return newCe;});
              setTimeSeriesValues(cv => {
                const newCv = {...cv}; 
                newCv[type] = [0]; 
                return newCv;})
              setTimeSeriesResolution(cr => {
                const newCr = {...cr}; 
                newCr[type] = e.target.value as resolutionStep; 
                return newCr;})
            }}
          >
            {resolutionSteps.map(r => <MenuItem key={r} value={r}>{r}</MenuItem>)}
          </Select>
        </FormControl>
      }</TableCell>
      <TableCell align="center">{timeSeriesResolution[type] === '1Y' ?
        <TextField
          id={type}
          label={timeSeriesTypeUnitReadable(type)}
          value={timeSeriesValues[type][0] ?? 0}
          sx={{width:'400px'}}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTimeSeriesErrors(ce => {
              const newCe = {...ce}; 
              delete newCe[type]; 
              return newCe;});
            if (event.target.value === '') {
              setTimeSeriesValues(cv => {
                const newCv = {...cv}; 
                newCv[type] = [0]; 
                return newCv;});
              return;
            }
            const i = parseInt(event.target.value);
            if(isNaN(i)) {
              setTimeSeriesErrors(ce => {
                const newCe = {...ce}; 
                newCe[type] = 'Es werden nur ganzzahlige Eingaben akzeptiert'; 
                return newCe;});
              return;
            }
            setTimeSeriesValues(cv => {
              const newCv = {...cv}; 
              newCv[type] = [i]; 
              return newCv;})
          }}
          helperText={timeSeriesErrors[type]}
          error={!!timeSeriesErrors[type]}
        />
        :
        <Stack direction='column' sx={{width:'400px'}}>
          <Button component='label' variant='contained' startIcon={<CloudUploadIcon />}>
            Upload file
            <VisuallyHiddenInput type='file' onChange={(e) => {
              processFile(e, type)
            }}/>
          </Button>
          {!!timeSeriesErrors[type] &&
            <>
              <br/>
              <Typography variant={'subtitle2'} sx={{mt:-2, color:'red'}}>{timeSeriesErrors[type]}</Typography>
            </>
          }
        </Stack>
      }</TableCell>
      <TableCell align="center">
        <TextField
          id={type + '-powerPrice'}
          label="EUR/kW"
          value={energyTariffsPriceForPower[type]}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEnergyTariffsPriceForPowerErrors(ce => {
              const newCe = {...ce}; 
              delete newCe[type]; 
              return newCe;});
            if (event.target.value === '') {
              setEnergyTariffsPriceForPower(cv => {
                const newCv = {...cv}; 
                newCv[type] = 0; 
                return newCv;})
              return;
            }
            const i = parseInt(event.target.value);
            if(isNaN(i)) {
              setEnergyTariffsPriceForPowerErrors(ce => {
                const newCe = {...ce}; 
                newCe[type] = 'Es werden nur ganzzahlige Eingaben akzeptiert'; 
                return newCe;});
              return;
            }
            setEnergyTariffsPriceForPower(cv => {
              const newCv = {...cv}; 
              newCv[type] = i; 
              return newCv;})
          }}
          helperText={energyTariffsPriceForPowerErrors[type]}
          error={!!energyTariffsPriceForPowerErrors[type]}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton aria-label="delete" onClick={() => {
          setTimeSeriesValues(ce => {
            const newCe = {...ce}; 
            newCe[type] = []; 
            return newCe;});
          setTimeSeriesResolution(cv => {
            const newCv = {...cv}; 
            newCv[type] = '1Y';
            return newCv;
          });
          setTimeSeriesErrors(cv => {
            const newCv = {...cv}; 
            delete newCv[type];
            return newCv;
          });
          setEnergyTariffsPriceForPower(cv => {
            const newCv = {...cv}; 
            newCv[type] = 0; 
            return newCv;
          });
          setEnergyTariffsPriceForPowerErrors(ce => {
            const newCe = {...ce}; 
            delete newCe[type]; 
            return newCe;
          });
        }}>
          <DeleteIcon/>
        </IconButton>
      </TableCell>
    </TableRow>;
  }

  function resultBox(assetTitle: string) {
    const viewData = result[assetTitle];
    return <Box key={assetTitle} sx={{mb:5,p:3}} bgcolor={hashStringToColor(assetTitle, 0.5)}>
      <Typography id="input-slider" variant="h6" gutterBottom>
        {assetTitle + ': ' + Math.round(viewData.amount ?? 0) + ' kWh'}
      </Typography>
      {(['power', 'capacity', 'area'] as propertyType[]).filter(p => viewData[p] !== null).map(property => {
        const data = viewData[property]!;
        const pos = viewDataPos(data);
        const value: number[] = [];
        const marks: {value:number,label:string}[] = [];
        Object.entries(pos).forEach(([k, v]) => {
          if (v === null) return;
          switch(k) {
            case 'is': 
              value.push(data.is);
              marks.push({value:data.is,label:'Ist'});
              break;
            case 'target':
              value.push(data.target!);
              marks.push({value:data.target!,label:(data.targetMode ? 'Soll (!)' : 'Soll')});
              break;
            case 'max':
              value.push(data.max!);
              marks.push({value:data.max!,label:'Max'});
              break;
          }
        })

        return <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={3} sx={{mt:5}}>
          <Typography id="input-slider" gutterBottom>
            {propertyTypeReadable(property)}
          </Typography>
          <Tooltip title={marks.map(m => m.label + ': ' + m.value).join(', ')}>
            <Slider
              getAriaLabel={() => assetTitle}
              valueLabelDisplay="on"
              getAriaValueText={() => assetTitle}
              value={value}
              marks={marks}
              disabled={computeState === 'PROCESSING'}
              onChange={(event: Event, newValue: number | number[], activeThumb: number) => {
                if(initializingData.current !== false) return;

                const currentDataNew = newValue as number[];
                if (lastData.current !== null && JSON.stringify(lastData.current) === JSON.stringify(currentDataNew)) {
                  return;
                }

                const directionRight = lastData.current !== null ?
                  lastData.current.reduce((a, b) => a + b, 0) <= currentDataNew.reduce((a, b) => a + b, 0) : null;

                lastData.current = [...currentDataNew];

                const getPropMoved = function(data: ResultViewProp, currentDataNew: number[], activeThumb: number) {
                  const propsSameValue = Object.entries(data)
                    .filter(([k, v]) => v === currentDataNew[activeThumb])
                    .map(([k, v]) => k) as ('is' | 'target' | 'max')[];
                  const propsOrder = ['max', 'target', 'is'];
                  return propsSameValue.sort((a, b) => propsOrder.indexOf(a) - propsOrder.indexOf(b))[0] ?? null;
                }

                const updateResultView = function(resultView: ResultViewProp, pos: any, currentDataNew: number[]) {
                  Object.entries(pos).forEach(([k, posIdx]) => {
                    switch(k) {
                      case 'is': 
                        resultView[k] = currentDataNew[posIdx as number];
                        break;
                      case 'target':
                      case 'max':
                        resultView[k] = (posIdx !== null ? currentDataNew[posIdx as number] : null);
                        break;
                    }
                  });
                }

                if (propMoved.current === null) {
                  propMoved.current = getPropMoved(data, currentDataNew, activeThumb);
                  if (propMoved.current === null) {
                    const dataR : ResultViewProp = {'is':0,'target':null,'targetMode':false,'max':null}; 
                    updateResultView(dataR, viewDataPos(data), currentDataNew);
                    propMoved.current = getPropMoved(dataR, currentDataNew, activeThumb);
                  }
                }

                if (propMoved.current === null) {
                  return;
                }

                const pos = viewDataPos(data, directionRight, propMoved.current);

                setResult(r => {
                  const newR = {...r}; 
                  updateResultView(newR[assetTitle][property]!, pos, currentDataNew);

                  if (propMoved.current === 'is') {
                    setComputeResultInitial(null);
                    setComputeResultOnlyPurchase(null);
                    setComputeResultNoRestrictions(null);
                    setComputeStateOnlyPurchase('NEW');
                    setComputeStateNoRestrictions('NEW');

                  } else if (propMoved.current === 'target') {
                    newR[assetTitle][property]!.targetMode = true;
                    newR[assetTitle][property]!.max = null;

                  } else if (propMoved.current === 'max' &&
                    newR[assetTitle][property]!.target !== null && 
                    newR[assetTitle][property]!.max !== null && 
                    newR[assetTitle][property]!.target! > newR[assetTitle][property]!.max!) {
                    newR[assetTitle][property]!.target = newR[assetTitle][property]!.max;
                  }

                  return newR;
                });
                setComputeState('UPDATED');
              }}
              onChangeCommitted={(event: React.SyntheticEvent | Event, newValue: number | number[]) => {
                lastData.current = null;
                propMoved.current = null;
              }}
              track={false}
              min={0}
              max={round(Math.max(data.is, data.target ?? 0, data.max ?? 0), 50, 10)}
            />
          </Tooltip>
          <Button
            variant="contained"
            sx={{width:'250px'}}
            disabled={computeState === 'PROCESSING'}
            onClick={() => {
              if(initializingData.current !== false) return;

              if (data.targetMode) {
                setResult(r => {
                  const newR = {...r}; 
                  newR[assetTitle][property]!.targetMode = false;
                  return newR;
                });
                setComputeState('UPDATED');
              } else if (data.max === null) {
                setResult(r => {
                  const newR = {...r}; 
                  if(newR[assetTitle][property]!.target !== null)
                    newR[assetTitle][property]!.max = newR[assetTitle][property]!.target! * 1.1;
                  return newR;
                });
                setComputeState('UPDATED');
              } else {
                setResult(r => {
                  const newR = {...r}; 
                  newR[assetTitle][property]!.max = null;
                  return newR;
                });
                setComputeState('UPDATED');
              }
            }}
          >
            {data.targetMode ?
              'Soll nicht fixieren'
              :
              (data.max === null ?
                'Max hinzufügen'
                :
                'Max entfernen'
              )
            }
          </Button>
        </Stack>
      })}
    </Box>;
  };

  function viewDataPos(viewProps: ResultViewProp, directionRight?: boolean | null, prop?: 'is' | 'target' | 'max' | null) {
    const copyViewProps: Partial<ResultViewProp> = {...viewProps};
    const delta = function(k: keyof ResultViewProp, directionRight?: boolean | null, prop?: 'is' | 'target' | 'max' | null) {
      if (directionRight === undefined || directionRight === null || 
        prop === undefined || prop === null) return 0;
      return k === prop ? (directionRight ? 1 : -1) : 0;
    };
    const sortable = Object.fromEntries(
      Object.entries(copyViewProps).filter(([k,v]) => typeof v === "number").sort(([ka,a],[kb,b]) => 
        (a as number) + delta(ka as keyof ResultViewProp, directionRight, prop) - 
        (b as number) - delta(kb as keyof ResultViewProp, directionRight, prop))
    );
    const pos: {is:number,target:number|null,max:number|null} = {is:0,target:null,max:null};
    Object.keys(sortable).forEach(function(key, idx) {
      pos[key as keyof typeof pos] = idx;
    });
    return pos;
  }

  function round(number: number, increment: number, offset: number) {
    const result = Math.ceil(number / increment ) * increment;
    if (Math.abs(number - result) < offset) {
      return result + increment;
    }
    return result;
  }

  return (
    <Box sx={{m:3}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
          <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={10}>
            <Tabs value={valueMain} onChange={handleChangeMain}>
              <Tab label="Eingaben" {...a11yProps(0)} disabled={computeState === 'PROCESSING' || computeResult !== null} />
              <Tab label="Energieversorgungskonzept" {...a11yProps(1)} disabled={computeResult === null} />
            </Tabs>
            <LoadingButton
              loading={computeState === 'PROCESSING'}
              loadingPosition="end"
              endIcon={<></>}
              variant={requestDataValid === true && (computeState === 'NEW' || computeState === 'UPDATED' || computeState === 'PROCESSING') ? 'contained' : 'outlined'}
              onClick={() => {
                if (!(requestDataValid === true && (computeState === 'NEW' || computeState === 'UPDATED'))) {
                  console.log('skip');
                  return;
                }
                computeSession();
              }}
            ><Box sx={{pr:(computeState === 'PROCESSING' ? 3 : 0)}}>{computeState === 'UPDATED' ? 'Aktualisieren' : 'Berechnen'}</Box></LoadingButton>
            {computeState === 'PROCESSING' &&
              <Typography variant="subtitle1">
                {'Wird berechnet...'}
              </Typography>
            }
          </Stack>
          <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
            <Button variant="contained" onClick={() => {
              setValueMain(0);
              newSession();
            }}>Eingaben zurücksetzen</Button>
            <Button variant="outlined" onClick={clearDB}>Browser-DB leeren</Button>
          </Stack>
        </Stack>
      </Box>
      <CustomTabPanel value={valueMain} index={0}>
        <Box sx={{mt:3}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Standort" {...a11yProps(0)} />
              <Tab label="Energiebedarf" {...a11yProps(1)} />
              <Tab label="Anlagen (Ist-Zustand)" {...a11yProps(2)} />
              <Tab label="Anlagen (Beschränkungen)" {...a11yProps(3)} />
              <Tab label="Energielieferverträge" {...a11yProps(4)} />
              <Tab label="Finanzierungsdaten" {...a11yProps(5)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              {/*Standort
              <TextField
                id="input-location"
                label="Standort"
                value={location}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLocation(event.target.value);
                }}
              />*/}
              <TextField
                id="input-longitude"
                label="Längengrad"
                value={longitude ?? ''}
                type="number"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value === '') {
                    setLongitude(null);
                    return;
                  }
                  const i = parseFloat(event.target.value);
                  if(isNaN(i)) {
                    setLongitude(null);
                    return;
                  }
                  setLongitude(i);
                }}
              />
              <TextField
                id="input-latitude"
                label="Breitengrad"
                value={latitude ?? ''}
                type="number"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value === '') {
                    setLatitude(null);
                    return;
                  }
                  const i = parseFloat(event.target.value);
                  if(isNaN(i)) {
                    setLatitude(null);
                    return;
                  }
                  setLatitude(i);
                }}
              />
            </Box>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Energiebedarf</TableCell>
                    <TableCell align="center">Auflösung</TableCell>
                    <TableCell align="center">Daten / Datei</TableCell>
                    <TableCell align="right">Entfernen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(['electricityConsumption', 'heatConsumption', 'gasConsumption'] as timeSeriesConsumptionType[])
                    .filter(timeSeriesConsumptionType => timeSeriesValues[timeSeriesConsumptionType].length > 0)
                    .map(timeSeriesConsumptionType =>
                      rowConsumption(timeSeriesConsumptionType)
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {(['electricityConsumption', 'heatConsumption', 'gasConsumption'] as timeSeriesConsumptionType[])
              .filter(timeSeriesConsumptionType => timeSeriesValues[timeSeriesConsumptionType].length === 0)
              .length > 0 &&
                <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2} sx={{my:3}}>
                  <FormControl>
                    <InputLabel id={'consumption-select-label'}>Energiebedarf hinzufügen</InputLabel>
                    <Select
                      labelId={'consumption-select-label'}
                      value={addConsumption ?? ''}
                      label='Energiebedarf hinzufügen'
                      sx={{width:'400px'}}
                      onChange={e => setAddConsumption(e.target.value as timeSeriesConsumptionType)}
                    >
                      {(['electricityConsumption', 'heatConsumption', 'gasConsumption'] as timeSeriesConsumptionType[])
                        .filter(timeSeriesConsumptionType => timeSeriesValues[timeSeriesConsumptionType].length === 0)
                        .map(r => <MenuItem key={r} value={r}>{timeSeriesTypeReadable(r)}</MenuItem>)}
                    </Select>
                  </FormControl>
                  <IconButton aria-label="add" onClick={() => {
                    if (addConsumption === null) return;
                    setTimeSeriesValues(cv => {
                      const newCv = {...cv}; 
                      newCv[addConsumption] = [0];
                      setAddConsumption(null);
                      return newCv;
                    });
                  }}>
                    <AddIcon/>
                  </IconButton>
                </Stack>
            }
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            <AssetContentCurrent tabStateOwner={[value2, setValue2]}/>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={3}>
            <AssetContentRestrictions tabStateOwner={[value2, setValue2]}/>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={4}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Energiebedarf</TableCell>
                    <TableCell align="center">Auflösung</TableCell>
                    <TableCell align="center">Daten / Datei</TableCell>
                    <TableCell align="center">Leistungspreis</TableCell>
                    <TableCell align="right">Entfernen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(['electricalEnergyPurchase', 'electricalEnergyFeedin', 'thermalEnergyPurchase', 'naturalGasPurchase'] as timeSeriesPurchaseType[])
                    .filter(timeSeriesPurchaseType => timeSeriesValues[timeSeriesPurchaseType].length > 0)
                    .map(timeSeriesPurchaseType => rowEnergyTariff(timeSeriesPurchaseType)
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {(['electricalEnergyPurchase', 'electricalEnergyFeedin', 'thermalEnergyPurchase', 'naturalGasPurchase'] as timeSeriesPurchaseType[])
              .filter(timeSeriesPurchaseType => timeSeriesValues[timeSeriesPurchaseType].length === 0)
              .length > 0 &&
                <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2} sx={{my:3}}>
                  <FormControl fullWidth>
                    <InputLabel id={'purchase-select-label'}>Liefervertrag hinzufügen</InputLabel>
                    <Select
                      labelId={'purchase-select-label'}
                      value={addPurchase ?? ''}
                      label='Liefervertrag hinzufügen'
                      sx={{width:'400px'}}
                      onChange={e => setAddPurchase(e.target.value as timeSeriesPurchaseType)}
                    >
                      {(['electricalEnergyPurchase', 'electricalEnergyFeedin', 'thermalEnergyPurchase', 'naturalGasPurchase'] as timeSeriesPurchaseType[])
                        .filter(timeSeriesPurchaseType => timeSeriesValues[timeSeriesPurchaseType].length === 0)
                        .map(r => <MenuItem key={r} value={r}>{timeSeriesTypeReadable(r)}</MenuItem>)}
                    </Select>
                  </FormControl>
                  <IconButton aria-label="add" onClick={() => {
                    if (addPurchase === null) return;
                    setTimeSeriesValues(cv => {
                      const newCv = {...cv}; 
                      newCv[addPurchase] = [0];
                      setAddPurchase(null);
                      return newCv;
                    });
                  }}>
                    <AddIcon/>
                  </IconButton>
                </Stack>
            }
          </CustomTabPanel>

          <CustomTabPanel value={value} index={5}>
            <TextField
              id={'interestRate'}
              label={'Zins'}
              value={interestRate}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setInterestRate(DEFAULT_VALUES.interestRate);
                  return;
                }
                const i = parseFloat(event.target.value);
                if(isNaN(i)) {
                  setInterestRate(DEFAULT_VALUES.interestRate);
                  return;
                }
                setInterestRate(i);
              }}
            />
            <Stack direction='column' sx={{mt:3}}>
              Neuer Anlagen-Finanzierungseintrag:
              <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2} sx={{my:2}}>
                <FormControl sx={{width:400}}>
                  <InputLabel id={'asset-financial-data-asset-type-select-label'}>Anlagen-Typ</InputLabel>
                  <Select
                    id={'asset-financial-data-asset-type-select'}
                    labelId={'asset-financial-data-asset-type-select-label'}
                    value={addAssetFinancialDataAssetType ?? ''}
                    label='Anlagen-Typ'
                    onChange={e => setAddAssetFinancialDataAssetType(e.target.value as assetType)}
                  >
                    {(Object.keys(assetConfigs) as assetType[])
                      .map(r => <MenuItem key={r} value={r}>{assetConfigs[r].title}</MenuItem>)}
                  </Select>
                </FormControl>
                <TextField
                  id={'asset-financial-data-name'}
                  label={'Anlagen-Bezeichnung'}
                  value={addAssetFinancialDataName ?? ''}
                  sx={{width:400}}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.value === '') {
                      setAddAssetFinancialDataName(null);
                      return;
                    }
                    setAddAssetFinancialDataName(event.target.value);
                  }}
                  helperText={addAssetFinancialDataName !== null && addAssetFinancialDataName !== '' && addAssetFinancialDataName in financialValues ? 
                    'Anlagen-Bezeichnung bereits vergeben' : null}
                  error={addAssetFinancialDataName !== null && addAssetFinancialDataName !== '' && addAssetFinancialDataName in financialValues}
                />
                <IconButton 
                  id={'asset-financial-data-add-'} 
                  aria-label="add" 
                  disabled={addAssetFinancialDataAssetType === null || addAssetFinancialDataName === null || 
                    addAssetFinancialDataName === '' || addAssetFinancialDataName in financialValues}
                  onClick={() => {
                    if (addAssetFinancialDataAssetType === null || addAssetFinancialDataName === null) return;
                    setFinancialValues(fv => {
                      const newFv = {...fv}; 
                      newFv[addAssetFinancialDataName] = {
                        assetType: addAssetFinancialDataAssetType,
                        config: Object.values(DEFAULT_VALUES.financialValues)
                          .find((v : FinancialValueType) => v.assetType === addAssetFinancialDataAssetType)!.config,
                        default: false,
                      };
                      setAddAssetFinancialDataAssetType(null);
                      return newFv;
                    })
                  }}>
                  <AddIcon/>
                </IconButton>
              </Stack>
            </Stack>
            <TableContainer sx={{mt:3}} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Anlagen-Bezeichnung</TableCell>
                    <TableCell align="center">Anlagen-Typ</TableCell>
                    <TableCell align="center">CapEx / OpEx</TableCell>
                    <TableCell align="center">Nutzungsdauer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(financialValues).map(([assetTitle, data]) => {
                      if(!data) return;
                      const assetType = data.assetType as assetType;
                      const config = data.config;
                      return <TableRow
                        key={assetTitle}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                      <TableCell align="left" component="th" scope="row">
                        {assetTitle}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {assetConfigs[assetType].title}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <Stack direction='column' spacing={2}>
                          {(['power', 'capacity', 'area'] as propertyType[])
                            .filter(property => assetConfigs[assetType][property])
                            .map(property => {
                              const financialPropertyConfig = config[property]!;
                              return <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>{
                                  (['capex', 'opex'] as capexOpexPropertyType[])
                                  .map(financialProperty => {
                                    return <TextField 
                                      key={type + '-' + property + '-' + financialProperty}
                                      id={type + '-' + property + '-' + financialProperty}
                                      label={property + ' ' + financialProperty + ' (' + financialPropertyTypeReadable(financialProperty) + ')'}
                                      value={financialPropertyConfig[financialProperty]}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFinancialValuesErrors(ce => {
                                          const newCe = {...ce}; 
                                          if (newCe[assetTitle] !== undefined && 
                                              newCe[assetTitle]![property] !== undefined && 
                                              newCe[assetTitle]![property]![financialProperty] !== undefined)
                                            delete newCe[assetTitle]![property]![financialProperty]; 
                                          return newCe;
                                        });
                                        if (event.target.value === '') {
                                          setFinancialValues(cv => {
                                            const newCv = {...cv}; 
                                            config[property]![financialProperty] = 0; 
                                            return newCv;
                                          })
                                          return;
                                        }
                                        const i = parseInt(event.target.value);
                                        if(isNaN(i)) {
                                          setFinancialValuesErrors(ce => {
                                            const newCe = {...ce}; 
                                            newCe[assetTitle] = newCe[assetTitle] === undefined ? {} : newCe[assetTitle];
                                            newCe[assetTitle]![property] = newCe[assetTitle]![property] === undefined ? {} : newCe[assetTitle]![property];
                                            newCe[assetTitle]![property]![financialProperty] = 'Es werden nur ganzzahlige Eingaben akzeptiert'; 
                                            return newCe;
                                          });
                                          return;
                                        }
                                        setFinancialValues(cv => {
                                          const newCv = {...cv}; 
                                          config[property]![financialProperty] = i;
                                          return newCv;})
                                      }}
                                      helperText={financialValuesErrors[assetTitle] !== undefined && 
                                        financialValuesErrors[assetTitle]![property] !== undefined ? financialValuesErrors[assetTitle]![property]![financialProperty] : undefined}
                                      error={financialValuesErrors[assetTitle] !== undefined && 
                                        financialValuesErrors[assetTitle]![property] !== undefined && !!financialValuesErrors[assetTitle]![property]![financialProperty]}
                                    />;
                                  })}</Stack>
                              })
                            }
                          </Stack>
                      </TableCell>
                      <TableCell align="center">{
                        (() => {
                          const property = 'lifespan';
                          return <TextField 
                            key={type + '-' + property}
                            id={type + '-' + property}
                            label={financialPropertyTypeReadable(property)}
                            value={config[property]}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setFinancialValuesErrors(ce => {
                                const newCe = {...ce}; 
                                if (newCe[assetTitle] !== undefined)
                                  delete newCe[assetTitle]![property]; 
                                return newCe;});
                              if (event.target.value === '') {
                                setFinancialValues(cv => {
                                  const newCv = {...cv}; 
                                  config[property] = 0; 
                                  return newCv;})
                                return;
                              }
                              const i = parseInt(event.target.value);
                              if(isNaN(i)) {
                                setFinancialValuesErrors(ce => {
                                  const newCe = {...ce}; 
                                  newCe[assetTitle] = newCe[assetTitle] === undefined ? {} : newCe[assetTitle];
                                  newCe[assetTitle]![property] = 'Es werden nur ganzzahlige Eingaben akzeptiert'; 
                                  return newCe;});
                                return;
                              }
                              setFinancialValues(cv => {
                                const newCv = {...cv}; 
                                config[property] = i;
                                return newCv;})
                            }}
                            helperText={financialValuesErrors[assetTitle] !== undefined ? financialValuesErrors[assetTitle]![property] : undefined}
                            error={financialValuesErrors[assetTitle] !== undefined && !!financialValuesErrors[assetTitle]![property]}
                          />
                        })()
                      }</TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={valueMain} index={1}>
        <Box sx={{mt:3}}>
          {computeResult ?
            <Grid container spacing={2}>
              <Grid xs={6}>
                {computeResult.target_scenario.components.filter((c: any) => 
                  ['ELECTRICAL_ENERGY_PURCHASE', 'ELECTRICAL_ENERGY_FEEDIN', 
                    'NATURAL_GAS_PURCHASE', 'THERMAL_ENERGY_PURCHASE'].includes(c.component_type) && 
                    c.produced_energy?.map((pe: {amount:number}) => pe.amount).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0) > 0)
                  .map((c: any) =>
                    <Box key={c.component_type} sx={{mb:5,p:3}} bgcolor={hashStringToColor(tariffReadable(c.component_type) ?? '', 0.5)}>
                      <Typography id="input-slider" variant="h6" gutterBottom>
                        {tariffReadable(c.component_type) + ': ' + Math.round(c.produced_energy.map((pe: {amount:number}) => pe.amount).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0)) + ' kWh'}
                      </Typography>
                    </Box>
                )}
                {(Object.keys(result) as string[])
                    .filter((a) => (result[a].amount ?? 0) > 0)
                    .map(assetTitle => {
                  return resultBox(assetTitle);
                })}

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Nicht genutzte Komponenten</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {(Object.keys(result) as string[])
                        .filter((a) => (result[a].amount ?? 0) === 0)
                        .map(assetTitle => {
                      return resultBox(assetTitle);
                    })}
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid xs={6}>
                  <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
                    <ToggleButtonGroup
                      color="primary"
                      value={alignmentChart}
                      exclusive
                      onChange={handleChangeChart}
                    >
                      <ToggleButton value="compare">Kostenvergleich (jährlich, EUR)</ToggleButton>
                      <ToggleButton value="fragments">Kostenbestandteile (jährlich, EUR)</ToggleButton>
                      <ToggleButton value="distribution">Erzeugungsportfolio (jährlich, kWh)</ToggleButton>
                    </ToggleButtonGroup>

                    {alignmentChart === 'distribution' && energyComponentDistributionData &&
                      <Box sx={{'height': '600px','width': '600px'}}>
                        <Doughnut 
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                position: 'bottom' as const,
                              },
                              title: {
                                display: true,
                                text: 'Erzeugungsportfolio (jährlich, kWh)',
                              },
                            },
                          }} 
                          data={energyComponentDistributionData} 
                        />
                      </Box>
                    }

                    {alignmentChart === 'fragments' && costFragmentsData &&
                      <Box sx={{'height': '800px','width': '800px'}}>
                        <Bar 
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                position: 'bottom' as const,
                              },
                              title: {
                                display: true,
                                text: 'Kostenbestandteile (jährlich, EUR)',
                              },
                            },
                            scales: {
                              x: {
                                stacked: true,
                              },
                              y: {
                                stacked: true,
                              },
                            },
                          }} 
                          data={costFragmentsData} 
                        />
                      </Box>
                    }

                    {alignmentChart === 'compare' && costCompareData &&
                      <Box sx={{'height': '800px','width': '800px'}}>
                        <Bar 
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                position: 'bottom' as const,
                              },
                              title: {
                                display: true,
                                text: 'Kostenvergleich (jährlich, EUR)',
                              },
                            },
                            scales: {
                              x: {
                                stacked: true,
                              },
                              y: {
                                stacked: true,
                              },
                            },
                          }} 
                          data={costCompareData} 
                        />
                      </Box>
                    }
                  </Stack>
              </Grid>
            </Grid>
            :
            <>Noch kein Ergebnis</>
          } 
        </Box>
      </CustomTabPanel>
      <Grid container justifyContent="flex-end">
        <Typography variant="subtitle2" sx={{mt:10}}>Version: 0.0.9 (13.02.2024)</Typography>
      </Grid>
    </Box>);
}

export default App;
